import React, { Component, Fragment } from "react"
import { Container, Box, makeStyles } from "@material-ui/core"
import logo from "./img/logo_color.png"
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'

import styled from "styled-components"

const useStyles = makeStyles((theme) => ({
  label: {
    padding: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      fontSize: "2.2em",
    }
  },
  phone: {
    padding: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      fontSize: "1.2em",
    }
  },
}));

export default () => {
  const classes = useStyles();
		return <Fragment>
        <Wrapper>
          <MyContainer>
            <WrapperContent maxWidth="md">
              <Header>
                <Logo>
                  <LogoImg>
                    <img src={logo} />
                  </LogoImg>
                </Logo>
                <Phone>
                  <Typography className={classes.phone} variant="h3" component="h2" gutterBottom>
                    8-800-550-9076
                  </Typography>
                </Phone>
              </Header>
              <Content elevation={3} >
                <UnderConstruction>
                  <Typography className={classes.label} variant="h2" component="h2" gutterBottom>
                    Сайт на&nbsp;реконструкции
                  </Typography>
                </UnderConstruction>
                <Managers>
                  <Typography variant="h5" gutterBottom>
                    По&nbsp;всем вопросам обращайтесь к&nbsp;менеджерам:
                  </Typography>
                  <Typography variant="h5" gutterBottom>
                    +7 (495) 541-1378<br />
                    +7 (4855) 20-44-42
                  </Typography>
                  <Typography variant="h5" gutterBottom>
                  <br />clients@rus-msu.com<br />
                    megaplast-rybinsk@mail.ru

                  </Typography>
                </Managers>
              </Content>
            </WrapperContent>
          </MyContainer>
        </Wrapper>
    </Fragment>
}
const Wrapper = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column"
}))

const MyContainer = styled(Container)(() => ({
  flex: "1 0 auto"
}))

const Header = styled(Box)(() => ({
  display: "flex",
  justifyContent: "space-between",
  marginTop: "10px"
}))
const Logo = styled(Box)(() => ({
  //
}))
const LogoImg = styled(Box)(() => ({
  padding: "10px 0"
}))
const Phone = styled(Box)(() => ({
  paddingTop: "10px",
  color: "#424242"
}))
const Content = styled(Paper)(() => ({
  marginTop: "150px",
  padding: "20px"
}))
const UnderConstruction = styled(Box)(() => ({
  marginBottom: "100px"
}))

const Managers = styled(Box)(() => ({
  marginTop: "40px"
}))

const WrapperContent = styled(Container)(() => ({
  margin: "0",
  padding: "0px !important"
}))